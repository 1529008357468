import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import PageLinkComponent from '@/components/page/linkComponent';
import {
	KeyboardArrowLeftRounded as KeyboardArrowLeftRoundedIcon,
	KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon,
	StarRounded as StarRoundedIcon,
} from '@mui/icons-material';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Variants } from 'framer-motion';
import Slider from 'react-slick';

export default function TestimonialsSection() {
	const animationFadeInBackground: Variants = {
		offscreen: {
			opacity: 0,
			color  : '#000000',
		},
		onscreen: {
			opacity   : 1,
			transition: {
				duration: 1,
				ease    : 'easeInOut',
			},
			color          : '#ffffff',
			backgroundColor: '#000000',
		},
	};
	return (
		<Container disableGutters maxWidth='xl' className='borderedContainer'>
			<LayoutAnimationStaggerParent>
				<LayoutAnimation
					hasStagger
					animationConfig={animationFadeInBackground}>
					<Box sx={{ py: { xs: 3, sm: 20 }, px: 1 }}>
						<Typography className='sectionTitle' textAlign='center' mb={15}>
							What Clover Customers are saying about Invoiss
						</Typography>
						<Box
							sx={{
								'py'          : { xs: 1, sm: 5 },
								'.slick-arrow': { ':before': { display: 'none' } },
								'.slick-prev' : { left: 25, zIndex: 500 },
								'.slick-next' : { right: 25, zIndex: 500 },
								'.slickPaper' : {
									'overflow'        : 'hidden',
									'backgroundColor' : 'rgba(29,29,31,0.72)',
									'backgroundFilter': 'saturate(180%) blur(20px)',
									'width'           : '100%',
									'display'         : 'flex',
									'flexDirection'   : 'column',
									'borderRadius'    : 4,
									'height'          : 500,
									'p'               : { xs: 2, md: 5 },
									'transition'      : '.2s',
									'textDecoration'  : 'none !important',
									'color'           : '#ffffff',
									'border'          : 1,
									'borderColor'     : '#1f1f1f',
									':hover'          : {
										cursor: 'pointer',
										filter: 'brightness(1.5)',
									},
								},
								'.testimonialTitle': {
									fontWeight          : 'bold',
									fontSize            : { xs: '1.5rem !important', sm: '2rem !important' },
									background          : '-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
									// background: '-webkit-linear-gradient(45deg, #20BF55 30%, #01BAEF 90%)',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor : 'transparent',
								},
								'.testimonialText': {
									overflow       : 'hidden',
									textOverflow   : 'ellipsis',
									display        : '-webkit-box',
									WebkitLineClamp: 10,
									WebkitBoxOrient: 'vertical',
									whiteSpace     : 'unset',
									fontSize       : { xs: '1.5rem !important', sm: '1.7rem !important' },
									height         : 250,
									my             : 3,
								},
								'.testimonialOrigin': {
									background          : '-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor : 'transparent',
									fontWeight          : '500',
									fontStyle           : 'italic',
									fontSize            : { xs: '1.5rem !important', sm: '1.7rem !important' },
								},
							}}>
							<Slider
								autoplay
								// centerMode
								speed={1000}
								autoplaySpeed={4000}
								nextArrow={(
									<Box>
										<KeyboardArrowRightRoundedIcon
											sx={{
												color       : 'black',
												bgcolor     : 'white',
												borderRadius: 3,
											}}
										/>
									</Box>
								)}
								prevArrow={(
									<Box>
										<KeyboardArrowLeftRoundedIcon
											sx={{
												color       : 'black',
												bgcolor     : 'white',
												borderRadius: 3,
											}}
										/>
									</Box>
								)}
								slidesToShow={3}
								slidesToScroll={2}
								responsive={[
									{
										breakpoint: 768,
										settings  : { slidesToShow: 1, slidesToScroll: 1, infinite: false },
									},
									{
										breakpoint: 1200,
										settings  : { slidesToShow: 2, slidesToScroll: 1, infinite: false },
									},
								]}>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"I am very impressed with this app's customer service. Always timely with
												responses and did everything they could to meet my handful of requests to help
												make this app work better for me and save me time in my monthly billing cycle.
												I recommend them based on customer service alone, let alone a great app."
											</Typography>
											<Typography className='testimonialTitle'>
												CORNER HARDWARE AND MORE
											</Typography>
											<Typography className='testimonialOrigin'>
												From Orchard, Nebraska, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"I own a small cleaning supply business. We have a lot of different types of
												business we do like in store sales, online sales, phone sales/ship outs, mechanic
												shop repairs (service) and certification classes as well as estimates, work orders,
												bid and quotes. We needed an all in one solution because we were using like 5
												different systems to manage it all. I actually bought Invoiss in a desperate attempt
												to gain the functionality I needed after realizing (and freaking out about it) that
												the Clover POS and the other apps I had bought did not have the functionality I
												needed. I was literally FLOORED. This is what I have needed for 20 years. After we
												bought the app, it took my sales manager and I (63 and 45 respectively and not
												terribly great at computers) about a day to figure out how to use it. The you tube
												videos are great but honestly it's so intuitive and user friendly you almost don't
												need them...almost. I have received great customer support as well. 10 stars!!!!"
											</Typography>
											<Typography className='testimonialTitle'>
												Carpet Cleaner Supplies
											</Typography>
											<Typography className='testimonialOrigin'>
												From Euless, Texas, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"I do not know where to start with how amazing Invoiss has been for our
												company. Our company was ecstatic to finally be in 2022 with our new clover
												POS and its functionality. The major flaw we saw was the invoicing system.
												After much research into various apps through clover we were able to deduce
												that Invoiss was the most ideal for our needs."
											</Typography>
											<Typography className='testimonialTitle'>
												RICHMOND PLUMBING SPECIA
											</Typography>
											<Typography className='testimonialOrigin'>
												From Richmond, Virginia, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"Buy Now!! Outstanding Invoicing/Cashiering from an AWESOME Development Company! As
												a large public sector organization, Invoiss software fulfilled multiple
												cashiering & invoicing needs with well-architected software showcasing a full set of
												functions - all with Clover! Users created intuitive workflows easily and training
												was minimal! In 15 mins, cashiers were up to speed! Top-notch developers delivered
												custom work so quickly and flawlessly at a reasonable price! Imagine - the Business
												team approved Invoiss, the GUI was configured, users trained with custom
												fields/functions/ reporting in LESS THAN 10 CALENDAR DAYS - AMAZING! The quality,
												precision, and speed of the application is truly impressive. This platform and their
												API are being leveraged to create new opportunities to integrate various systems to
												save money AND time, lower costs, consolidate payment card device vendors, meet PCI
												compliance, and improve the organization's IT Security posture. Pricing is a STEAL!
												BUY TODAY! Hats off to Invoiss for providing outstanding technology & expertise.
												Also, they are a pleasure to work with!
											</Typography>
											<Typography className='testimonialTitle'>
												LONG BEACH DEVELOPMENT SERVICES
											</Typography>
											<Typography className='testimonialOrigin'>
												From City of Long Beach, California, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"Such a great tool!. It amazingly syncs with my orders in real-time and I can
												turn clover orders into invoices. I can also sync my invoices into Clover with
												all taxes, fees, and tips. This is by far one of the best invoicing apps I've
												ever had. I'm also using their inventory feature, which syncs my item count
												once I create invoices and update stock. kudos to the Invoiss team!"
											</Typography>
											<Typography className='testimonialTitle'>
												JAFFA SEA LLC - ZIGGYS BURGER
											</Typography>
											<Typography className='testimonialOrigin'>
												From San Francisco, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"Invoiss is a real 5 stars!. Invoiss syncs and shows all my orders along with
												their payments immediately after I ring up. It's amazing. And I love the fact
												that I can sync my invoices back to my clover dashboard in real time along
												with all items & taxes. I also love Invoiss and it's team, very supportive."
											</Typography>
											<Typography className='testimonialTitle'>
												ALTERCLEANERS
											</Typography>
											<Typography className='testimonialOrigin'>
												From San Francisco, California, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"Invoiss Rocks!. This is a great app. The customer service is also incredible.
												The app is easy to use and easy to train my staff on! Thank you Invoiss for
												making my life easier and my business more efficient!"
											</Typography>
											<Typography className='testimonialTitle'>
												AERUS OF TOWSON
											</Typography>
											<Typography className='testimonialOrigin'>
												From Parkville, Maryland, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"Amazing App -Easy to use and Great support. The only clover app i use.
												Amazing support, and great product. This is the app we use for out order
												keeping purposes and to send out, and keep track on invoices. 5 stars all day"
											</Typography>
											<Typography className='testimonialTitle'>
												LUGERMAN INC
											</Typography>
											<Typography className='testimonialOrigin'>
												From Langhorne, Pennsylvania, USA
											</Typography>
										</Box>
									</Box>
								</div>
								<div>
									<Box sx={{ m: { xs: 1, sm: 5 } }}>
										<Box
											className='slickPaper'
											component={PageLinkComponent}
											href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
											target='_blank'>
											<Stack direction='row' spacing={0.2}>
												{[ ...Array( 5 ) ].map( ( _, index ) => (
													<StarRoundedIcon key={index} sx={{ fill: 'rgba(255, 142, 83, 90%)' }}/>
												) )}
											</Stack>
											<Typography className='testimonialText'>
												"Greatest app I've ever had. This app is easy to use and it has many great
												features."
											</Typography>
											<Typography className='testimonialTitle'>
												OASIS GRILL 91 DRUMM
											</Typography>
											<Typography className='testimonialOrigin'>
												From San Francisco, USA
											</Typography>
										</Box>
									</Box>
								</div>
							</Slider>
						</Box>
					</Box>
				</LayoutAnimation>
			</LayoutAnimationStaggerParent>
		</Container>
	);
}
