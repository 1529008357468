import { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import { Container, Stack, Typography } from '@mui/material';
import { motion, Variants } from 'framer-motion';
import AccordionHouseAccountFeature from './house-account-accordion';
import AccordionInventoryManagementFeature from './inventory-management-accordion';
import AccordionInvoicesEstimatesFeature from './invoices-estimates-accordion';
import AccordionMultipleLocationFeature from './locations-accordion';
import AccordionOrdersPurchasesFeature from './orders-purchases-accordion';
import AccordionPaymentsFeature from './payments-accordion';
import AccordionTeamPermissionsFeature from './team-permissions-accordion';

export const slideLeft: Variants = {
	offscreen: { x: 10, opacity: 0 },
	onscreen : {
		x         : 0,
		opacity   : 1,
		transition: {
			type    : 'spring',
			duration: .5,
		},
	},
};
export default function FeaturesAccordionSection() {
	
	const animationVariantFade: Variants = {
		offscreen: { opacity: 0 },
		onscreen : {
			opacity   : 1,
			transition: { duration: 2 },
		},
	};
	
	return (
		<Container
			disableGutters
			maxWidth='xl'
			className='borderedContainer'>
			<motion.div
				initial='offscreen'
				whileInView='onscreen'
				viewport={{ once: true, amount: 0.1 }}
				variants={animationVariantFade}>
				<Stack sx={{ px: { xs: 2, sm: 15 }, py: { xs: 5, md: 10 } }} spacing={2}>
					<Typography className='sectionTitle' textAlign='center'>
						Engineered to optimize your business operations!
					</Typography>
					<Typography className='sectionSubTitle' textAlign='center' color='text.secondary'>
						Perform any task with Invoiss
					</Typography>
				</Stack>
			</motion.div>
			<Stack
				sx={{
					'.MuiAccordion-root': {
						'borderRadius'  : 0,
						'bgcolor'       : '#ffffff',
						':before'       : { display: 'none' },
						'border'        : 0,
						'boxShadow'     : 0,
						'borderBottom'  : 1,
						':first-of-type': {
							borderTop  : 1,
							borderColor: 'divider',
						},
						'borderColor': 'divider',
					},
					'.accordionIcon': {
						color     : 'primary.main',
						fontWeight: 'bold',
						fontSize  : '35px !important',
					},
					'.MuiAccordionDetails-root': {
						bgcolor: '#F2F2F2',
					},
					'.MuiAccordionSummary-root': {
						'transition': '.5s all',
						':hover'    : {
							cursor : 'pointer',
							bgcolor: 'divider',
						},
					},
				}}>
				<LayoutAnimationStaggerParent transition={{ staggerChildren: .2 }}>
					<AccordionInvoicesEstimatesFeature/>
					<AccordionOrdersPurchasesFeature/>
					<AccordionHouseAccountFeature/>
					<AccordionPaymentsFeature/>
					<AccordionInventoryManagementFeature/>
					<AccordionMultipleLocationFeature/>
					<AccordionTeamPermissionsFeature/>
				</LayoutAnimationStaggerParent>
			</Stack>
		</Container>
	);
}
